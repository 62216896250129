import React, { useState, useEffect } from 'react';
import './App.css';
import backgroundImage from './background.gif';

const CountdownTimer = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function addLeadingZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  return (
    <div className="countdown">
      <div className="header">
        <div className="header-text">Insanity Gaming</div>
        <div className="countdown-text">LAUNCH COUNTDOWN</div>
      </div>
      <div className="timer-container">
        <div className="timer-box">
          <div className="timer-box-inner">
            <div className="time">{addLeadingZero(timeLeft.days || 0)}</div>
            <div className="label">Days</div>
          </div>
        </div>
        <div className="colon">:</div>
        <div className="timer-box">
          <div className="timer-box-inner">
            <div className="time">{addLeadingZero(timeLeft.hours || 0)}</div>
            <div className="label">Hours</div>
          </div>
        </div>
        <div className="colon">:</div>
        <div className="timer-box">
          <div className="timer-box-inner">
            <div className="time">{addLeadingZero(timeLeft.minutes || 0)}</div>
            <div className="label">Minutes</div>
          </div>
        </div>
        <div className="colon">:</div>
        <div className="timer-box">
          <div className="timer-box-inner">
            <div className="time">{addLeadingZero(timeLeft.seconds || 0)}</div>
            <div className="label">Seconds</div>
          </div>
        </div>
      </div>
      <div className="button-container">
        <div className="button-box">
          <div className="button-inner" onClick={() => window.location.href = 'https://discord.gg/'}>DISCORD</div>
        </div>
        <div className="button-box">
          <div className="button-inner" onClick={() => window.location.href = 'https://www.tiktok.com/'}>TIKTOK</div>
        </div>
      </div>
    </div>
  );
};

function App() {
  // Replace this date with your desired target date
  const targetDate = '2024-09-29 7:00:00';
  
  return (
    <div className="App" style={{backgroundImage: `url(${backgroundImage})`}}>
      <CountdownTimer targetDate={targetDate} />
    </div>
  );
}

export default App;
